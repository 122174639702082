<template>
    <div class="project">
        <main class="main">
            <div class="container">
                <h4>{{ $t('m.10003') }}</h4>
                <el-row :gutter="30" class="project-row" v-for="(arr, index) in investList" :key="index">
                    <el-col :xs="24" :sm="index % 2 ? 8 : 12" v-for="res in arr" :key="res._id">
                        <div
                            class="li-item pointer"
                            @click="
                                $router.push({
                                    path: '/project/detail',
                                    query: { id: res._id },
                                })
                            "
                        >
                            <img :src="res.image" :title="res.title" :alt="res.title" />
                            <div class="text">
                                <p :class="['title', { lang_en: $i18n.locale == 'en_US' }]">
                                    {{ res.title }}
                                </p>
                                <p class="des">{{ res.intro }}</p>
                                <p class="more pointer">
                                    {{ $t('m.10065') }}
                                </p>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </main>
        <home-footer></home-footer>
    </div>
</template>

<script>
import homeFooter from '../../components/homeFooter.vue';
export default {
    components: { homeFooter },
    created() {
        this.getInvestIndex();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    data() {
        return {
            investList: [],
            lastId: '',
        };
    },
    methods: {
        getInvestIndex() {
            this.$api
                .getInvestIndex({
                    platform: this.isMobile ? 'h5' : 'pc',
                })
                .then((res) => {
                    let list = [];
                    let k = 0;
                    res.data.list.forEach((item) => {
                        if (typeof list[k] == 'undefined') {
                            list[k] = [];
                        }
                        if (k % 2 == 0) {
                            //2个
                            list[k].push(item);
                            if (list[k].length == 2) {
                                k++;
                            }
                        } else {
                            //3个
                            list[k].push(item);
                            if (list[k].length == 3) {
                                k++;
                            }
                        }
                    });
                    this.investList = list;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .project {
        padding-top: vv(70);
        .main {
            padding-bottom: vv(20);
            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            h4 {
                font-size: vv(15);
                text-align: center;
                font-weight: 700;
            }
            .project-row {
                width: 100%;
                .li-item {
                    position: relative;
                    width: 100%;
                    height: vv(351);
                    overflow: hidden;
                    margin-top: vv(20);
                    img {
                        width: 100%;
                    }
                    .text {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 40px;
                        padding-bottom: vv(20);
                        text-align: center;
                        .title {
                            font-size: vv(18);
                            color: #fff;
                            &.lang_en {
                                font-size: vv(16);
                            }
                        }
                        .des {
                            margin: vv(8) 0 vv(20);
                            font-size: vv(14);
                            color: rgba(255, 255, 255, 0.8);
                        }
                        .more {
                            font-size: vv(13);
                            color: rgba(255, 255, 255, 0.8);
                            display: inline-block;
                            &:after {
                                margin-left: vv(5);
                                content: '';
                                display: inline-block;
                                width: vv(13);
                                height: vv(9);
                                background: url(~@/assets/imgs/more.png) center no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 769px) {
    .project {
        padding-top: 168px;
        .container {
            width: 769px;
        }
        .main {
            display: flex;
            justify-content: center;
            padding-bottom: 40px;
            h4 {
                display: none;
            }
            .project-row {
                margin-bottom: 30px;

                .li-item {
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    &:hover {
                        img {
                            transform: translate(-50%, -50%) scale(1.1);
                        }
                        .text {
                            bottom: 0;
                        }
                    }
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transition: all 0.5s;
                        transform: translate(-50%, -50%);
                    }
                    .text {
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: -80px;
                        padding: 40px 28px;
                        transition: all 0.5s;
                        .title {
                            font-size: 20px;
                            color: #fff;
                        }
                        .des {
                            margin: 10px 0 40px;
                            font-size: 15px;
                            line-height: 2;
                            color: rgba(255, 255, 255, 0.5);
                        }
                        .more {
                            font-size: 15px;
                            color: rgba(255, 255, 255, 1);
                            display: inline-block;
                            &:after {
                                margin-left: 10px;
                                content: '';
                                display: inline-block;
                                width: 26px;
                                height: 18px;
                                background: url(~@/assets/imgs/more.png) center no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                }
                &:nth-child(2n) {
                    .li-item {
                        height: calc(575px * 0.7);
                    }
                }
                &:nth-child(2n-1) {
                    .li-item {
                        height: calc(370px * 0.7);
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .project {
        .main {
            .container {
                width: 990px;
            }
            .project-row {
                .li-item {
                    .text {
                        .title {
                            font-size: 28px;
                        }
                        .des {
                            font-size: 24px;
                        }
                        .more {
                            font-size: 24px;
                        }
                    }
                }
                &:nth-child(2n) {
                    .li-item {
                        height: calc(575px * 0.9);
                    }
                }
                &:nth-child(2n-1) {
                    .li-item {
                        height: calc(370px * 0.9);
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .project {
        .main {
            .container {
                width: 1200px;
            }
            .project-row {
                &:nth-child(2n) {
                    .li-item {
                        height: 575px;
                    }
                }
                &:nth-child(2n-1) {
                    .li-item {
                        height: 370px;
                    }
                }
            }
        }
    }
}
</style>
